import dayjs from "dayjs";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// POSTS

export const getPostListsFirebase = async () => {
  const docRef = doc(db, "lists", "postLists");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`posts loaded`);
    return docSnap.data();
  } else {
    console.log("No post document");
  }
};

// THEORY AND PRACTICE

export const createActFirebase = async (post) => {
  await setDoc(doc(db, "activities", post.id), {
    id: post.id,
    pillar: post.pillar,
    title: post.title,
    slug: post.slug,
    image: post.image,
    content: post.content,
    today: post.today,
  });
  console.log(post.title + " created");
};

export const getActFirebase = async () => {
  const docRef = collection(db, "activities");
  const q = query(docRef, where("today", "==", true));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`activity loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No activities document");
  }
};

export const getTheoryFirebase = async (pillar) => {
  const docRef = doc(db, pillar, `${pillar}TheoryList`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(pillar + ` theory loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getPracticesNameFirebase = async (pillar) => {
  const docRef = doc(db, pillar, `${pillar}Practices`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`${pillar}Practices loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const getPracticesFirebase = async (pillar) => {
  const docRef = doc(db, pillar, `${pillar}PracticeList`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(pillar + `PracticeList loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

// export const getPracticesFirebase = async (pillar, type) => {
//   const docRef = doc(db, pillar, type);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     console.log(pillar + ` ${type} loaded`);
//     return docSnap.data();
//   } else {
//     console.log("No such document");
//   }
// };

export const createTheoryFirebase = async (pillar, TheoryList) => {
  await setDoc(doc(db, pillar, `${pillar}TheoryList`), {
    [`${pillar}TheoryList`]: TheoryList,
  });
  console.log(pillar + " theory list created");
};

export const createPracticeFirebase = async (pillar, practice, list) => {
  await setDoc(doc(db, pillar, practice), {
    [practice]: list,
  });
  console.log(pillar + " practice list created");
};

export const createMindPracticeListFirebase = async (
  pillar,
  typeListName,
  list
) => {
  await setDoc(doc(db, pillar, `${pillar}PracticeList`), {
    [typeListName[0]]: list[typeListName[0]],
    [typeListName[1]]: list[typeListName[1]],
  });
  console.log("mind practice list created");
};

export const createMovePracticeListFirebase = async (
  pillar,
  typeListName,
  list
) => {
  await setDoc(doc(db, pillar, `${pillar}PracticeList`), {
    [typeListName[0]]: list[0],
    [typeListName[1]]: list[1],
    [typeListName[2]]: list[2],
    [typeListName[3]]: list[3],
    [typeListName[4]]: list[4],
    [typeListName[5]]: list[5],
  });
  console.log("mind practice list created");
};

export const createNutriPracticeListFirebase = async (
  pillar,
  typeListName,
  list
) => {
  await setDoc(doc(db, pillar, `${pillar}PracticeList`), {
    [typeListName[0]]: list[0],
    [typeListName[1]]: list[1],
    [typeListName[2]]: list[2],
    [typeListName[3]]: list[3],
  });
  console.log("mind practice list created");
};

// SCHEDULE

export const getScheduleFirebase = async (userId) => {
  const docRef = doc(db, "schedule", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`schedule loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createScheduleFirebase = async (
  userId,
  periodList,
  scheduleList
) => {
  await setDoc(doc(db, "schedule", userId), {
    [periodList[0]]: scheduleList[0],
    [periodList[1]]: scheduleList[1],
    [periodList[2]]: scheduleList[2],
    [periodList[3]]: scheduleList[3],
    [periodList[4]]: scheduleList[4],
    [periodList[5]]: scheduleList[5],
    [periodList[6]]: scheduleList[6],
    [periodList[7]]: scheduleList[7],
    [periodList[8]]: scheduleList[8],
  });
  console.log("schedule created");
};

// APPOINTMENTS

export const getAppointmentsDayFirebase = async (day) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("day", "==", dayjs(day).format("DD-MM-YY")));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${dayjs(day).format("DD-MM-YY")} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsDocDayFirebase = async (day, proEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(
    docRef,
    where("day", "==", dayjs(day).format("DD-MM-YY")),
    where("proEmail", "==", proEmail)
  );
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(
      `appointments ${dayjs(day).format("DD-MM-YY")} - ${proEmail} loaded`
    );
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getAppointmentsPatFirebase = async (userEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("userEmail", "==", userEmail));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${userEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const createAppointmentFirebase = async (appointment) => {
  await setDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    proName: appointment.proName,
    proEmail: appointment.proEmail,
    day: appointment.day,
    dtStart: appointment.dtStart,
    dtEnd: appointment.dtEnd,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log(`appointment ${appointment.id} created`);
};

export const updateAppointmentFirebase = async (appointment) => {
  await updateDoc(doc(db, "appointments", appointment.id), {
    id: appointment.id,
    userId: appointment.userId,
    userName: appointment.userName,
    userEmail: appointment.userEmail,
    description: appointment.description,
    proName: appointment.proName,
    proEmail: appointment.proEmail,
    day: appointment.day,
    dtStart: appointment.dtStart,
    dtEnd: appointment.dtEnd,
    status: appointment.status,
    location: appointment.location,
    geo: appointment.geo,
    categories: appointment.categories,
  });
  console.log("appointment updated");
};

export const deleteAppointmentFirebase = async (appointment) => {
  await deleteDoc(doc(db, "appointments", appointment.id));
  console.log("appointment deleted");
};
