import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import css from "./Schedule.module.css";
import { UserContext } from "../../Context/UserContext";
import { getWeek } from "../../Utils/getDate";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import {
  createScheduleFirebase,
  getPracticesFirebase,
} from "../../Utils/firebaseClinic";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const Schedule = () => {
  const { user, userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [taskModal, setTaskModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskSlug, setTaskSlug] = useState("");
  const currentWeek = getWeek();
  const [h05h07, seth05h07] = useState(new Array(7).fill(""));
  const [h07h09, seth07h09] = useState(new Array(7).fill(""));
  const [h09h11, seth09h11] = useState(new Array(7).fill(""));
  const [h11h13, seth11h13] = useState(new Array(7).fill(""));
  const [h13h15, seth13h15] = useState(new Array(7).fill(""));
  const [h15h17, seth15h17] = useState(new Array(7).fill(""));
  const [h17h19, seth17h19] = useState(new Array(7).fill(""));
  const [h19h21, seth19h21] = useState(new Array(7).fill(""));
  const [h21h23, seth21h23] = useState(new Array(7).fill(""));
  const periodArray = [
    "h05h07",
    "h07h09",
    "h09h11",
    "h11h13",
    "h13h15",
    "h15h17",
    "h17h19",
    "h19h21",
    "h21h23",
  ];

  useEffect(() => {
    if (userData && userData.schedule) {
      seth05h07(userData.schedule.h05h07);
      seth07h09(userData.schedule.h07h09);
      seth09h11(userData.schedule.h09h11);
      seth11h13(userData.schedule.h11h13);
      seth13h15(userData.schedule.h13h15);
      seth15h17(userData.schedule.h15h17);
      seth17h19(userData.schedule.h17h19);
      seth19h21(userData.schedule.h19h21);
      seth21h23(userData.schedule.h21h23);
    }
  }, [user]);

  useEffect(() => {
    if (!taskModal) {
      setTaskName("");
    }
  }, [taskModal]);

  function getCurrentDayClass(day) {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? css.currentDay
      : "";
  }

  function handleTimeByDay(day) {
    const dayPor = dayjs(day).format("ddd");
    switch (dayPor) {
      case "Sun":
        return "Dom";
      case "Mon":
        return "Seg";
      case "Tue":
        return "Ter";
      case "Wed":
        return "Qua";
      case "Thu":
        return "Qui";
      case "Fri":
        return "Sex";
      case "Sat":
        return "Sáb";
      default:
        return;
    }
  }

  // function handleSaveSchedule() {
  //   const objTask = {
  //     title: taskName,
  //     description: taskDescription,
  //   };
  //   let newArr = [];

  //   switch (taskModal) {
  //     case "Inicio da Manhã":
  //       newArr = h05h07;
  //       newArr[selectedIndex] = objTask;
  //       seth05h07([...newArr]);
  //       break;
  //     case "Meio da Manhã":
  //       newArr = h07h09;
  //       newArr[selectedIndex] = objTask;
  //       seth07h09([...newArr]);
  //       break;
  //     case "Fim da Manhã":
  //       newArr = h09h11;
  //       newArr[selectedIndex] = objTask;
  //       seth09h11([...newArr]);
  //       break;
  //     case "Inicio da Tarde":
  //       newArr = h11h13;
  //       newArr[selectedIndex] = objTask;
  //       seth11h13([...newArr]);
  //       break;
  //     case "Meio da Tarde":
  //       newArr = h13h15;
  //       newArr[selectedIndex] = objTask;
  //       seth13h15([...newArr]);
  //       break;
  //     case "Fim da Tarde":
  //       newArr = h15h17;
  //       newArr[selectedIndex] = objTask;
  //       seth15h17([...newArr]);
  //       break;
  //     case "Inicio da Noite":
  //       newArr = h17h19;
  //       newArr[selectedIndex] = objTask;
  //       seth17h19([...newArr]);
  //       break;
  //     case "Meio da Noite":
  //       newArr = h19h21;
  //       newArr[selectedIndex] = objTask;
  //       seth19h21([...newArr]);
  //       break;
  //     case "Fim da Noite":
  //       newArr = h21h23;
  //       newArr[selectedIndex] = objTask;
  //       seth21h23([...newArr]);
  //       break;

  //     default:
  //       break;
  //   }

  //   const scheduleArray = {
  //     h05h07: h05h07,
  //     h07h09: h07h09,
  //     h09h11: h09h11,
  //     h11h13: h11h13,
  //     h13h15: h13h15,
  //     h15h17: h15h17,
  //     h17h19: h17h19,
  //     h19h21: h19h21,
  //     h21h23: h21h23,
  //   };

  //   console.log(scheduleArray);
  //   userData.schedule = scheduleArray;
  //   updateUserFirebase(userData);
  // }

  return (
    // <div className="containerContent">
    <div className={css.container}>
      <Head title="Schedule" description="Schedule - Mairini" />
      <div className={css.month}>
        <span className={css.cell_title}></span>

        {currentWeek.map((day, i) => (
          <span className={css.cell_title} key={i}>
            {handleTimeByDay(day)}{" "}
            <span className={`${css.dayNumber} ${getCurrentDayClass(day)}`}>
              {dayjs(day).format("DD")}
            </span>
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h05h07.map((task, idx) => (
          <span
            className={`${css.day_name}`}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Manhã`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h07h09.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Manhã`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h09h11.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Manhã`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h11h13.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Tarde`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h13h15.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Tarde`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h15h17.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Tarde`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h17h19.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Noite`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h19h21.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Noite`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h21h23.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Noite`);
              task && setTaskName(task.title);
              task && setTaskDescription(task.description);
              task && setTaskSlug(task.slug);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}
      </div>

      {taskModal && (
        <div className={css.modalContainer}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setTaskModal(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>
                {taskModal +
                  ` - ${handleTimeByDay(currentWeek[selectedIndex])} (${dayjs(
                    currentWeek[selectedIndex]
                  ).format("DD")})`}
              </h3>
              <h4>Nome da Atividade</h4>
              <p>{taskName}</p>
              <h4>Descrição da Atividade</h4>
              <p>{taskDescription}</p>
              {taskSlug && (
                <button
                  className="greenButton"
                  onClick={() => navigate(`/post/${taskSlug}`)}
                >
                  Abrir
                </button>
              )}

              <footer className={css.footer}>
                <button
                  className="deleteButton"
                  onClick={(e) => {
                    setTaskModal(false);
                  }}
                >
                  Fechar
                </button>
              </footer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
