import React, { useState, useEffect } from "react";

import css from "./TerAct.module.css";
import audio from "../../assets/audios/gong.wav";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";

export const ListeningNature = () => {
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let endAudio = new Audio(audio);

    if (timer) {
      setTimeout(function () {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        } else if (minutes > 0) {
          setMinutes((minutes) => minutes - 1);
          setSeconds(5);
        }
      }, 1000);
    }
    if (minutes === 0 && seconds === 0) {
      endAudio.play();
      setTimer(false);
      setStep(2);
    }
  }, [step, minutes, seconds, timer]);

  return (
    <div className={css.container}>
      <Head
        title="ListeningPresent"
        description={"ListeningPresent - Mairini"}
      />
      {step === 0 && (
        <div className={css.instructions}>
          <h3>Instruções</h3>
          <p>
            Antes de iniciar, escolha um local com sons naturais em que você não
            será interrompido.
          </p>
          <p>
            Durante a atividade você irá{" "}
            <span>
              fechar os olhos e levar a atenção para cada som que escutar no
              ambiente.
            </span>
          </p>
          <p>
            Enquanto estiver com a atenção nos sons,{" "}
            <span>observe os pensamentos que a sua mente vai te oferecer.</span>
          </p>
          <p>
            O treino consiste em observar a chegada desses pensamentos e voltar
            à atenção para os sons.
          </p>
          <p>Não existe uma forma correta ou errada de fazer essa prática.</p>
          <p>
            Escolha o tempo da atividade. Sugerimos começar com 3 minutos e, nas
            próximas práticas, conforme for se sentindo confortável, aumente o
            tempo.
          </p>
          <div className={css.time}>
            {minutes === 3 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(3)}
              >
                3 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(3)}>
                3 min
              </button>
            )}
            {minutes === 5 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(5)}
              >
                5 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(5)}>
                5 min
              </button>
            )}
            {minutes === 7 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(7)}
              >
                7 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(7)}>
                7 min
              </button>
            )}
            {minutes === 10 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(10)}
              >
                10 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(10)}>
                10 min
              </button>
            )}
            {minutes === 15 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(15)}
              >
                15 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(15)}>
                15 min
              </button>
            )}
            {minutes === 20 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(20)}
              >
                20 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(20)}>
                20 min
              </button>
            )}
            {minutes === 25 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(25)}
              >
                25 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(25)}>
                25 min
              </button>
            )}
            {minutes === 30 ? (
              <button
                className="defaultActiveButton"
                onClick={() => setMinutes(30)}
              >
                30 min
              </button>
            ) : (
              <button className="defaultButton" onClick={() => setMinutes(30)}>
                30 min
              </button>
            )}
          </div>
          <p>Aperte o botão abaixo para começar.</p>
          <button
            className={`greenButton ${css.startButton}`}
            onClick={() => {
              setStep(1);
              setTimer(true);
            }}
          >
            Iniciar
          </button>
        </div>
      )}
      {step === 1 && (
        <div>
          <h1>
            {minutes < 10 ? "0" + minutes : minutes} :{" "}
            {seconds < 10 ? "0" + seconds : seconds}
          </h1>
        </div>
      )}
      {step === 2 && (
        <div>
          <h3>Muito bem!</h3>
          <h3>
            Refaça esse exercício novamente 2-3 vezes por semana para continuar
            desenvolvendo sua habilidade de concentração.
          </h3>
          {/* <h3>
            Agora, escreva abaixo alguns pensamentos que a sua mente trouxe
          </h3>
          <Input /> */}
        </div>
      )}
    </div>
  );
};
