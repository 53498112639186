import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";

export const ProfilePage = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const traits = [
    "Afetividade Negativa",
    "Desinibição",
    "Distanciamento",
    "Dissociabilidade",
    "Anancastia",
  ];

  return (
    <>
      <Head title="Profile" description={"Profile - Mairini"} />
      {userData ? (
        <div className={css.profile}>
          <div className={css.profile1}>
            {userData.profilePermissions && userData.profilePermissions.id && (
              <i
                className={`material-icons ${css.cardIcon}`}
                onClick={() => navigate("/data")}
              >
                edit
              </i>
            )}
            <i className={`material-icons ${css.userIcon}`}>account_circle</i>
            <h4>{userData.displayName}</h4>
            <h4>Personalidade</h4>
            <p>Introvertido</p>
            <p>Criativo</p>
            <h4>Direções da vida</h4>
            <p>Família</p>
            <p>Tabalho</p>
            <p>Lazer</p>
          </div>

          <div className={css.profile5}>
            {userData.profilePermissions &&
              userData.profilePermissions.personalValues && (
                <i
                  className={`material-icons ${css.cardIcon}`}
                  onClick={() => navigate("/values")}
                >
                  edit_note
                </i>
              )}
            <h4>Valores</h4>
            {/* <div className={css.wheel}>
              <div className={css.wheel1}></div>
              <div className={css.wheel2}></div>
              <div className={css.wheel3}></div>
              <div className={css.wheel4}></div>
            </div> */}

            {userData.listValues && (
              <>
                <p>Relações familiares: {userData.listValues[0]}</p>
                <p>Companheiro afetivo: {userData.listValues[1]}</p>
                <p>Ser pai ou mãe: {userData.listValues[2]}</p>
                <p>Amizades: {userData.listValues[3]}</p>
                <p>Trabalho: {userData.listValues[4]}</p>
                <p>Educação: {userData.listValues[5]}</p>
                <p>Lazer e Bem-estar: {userData.listValues[6]}</p>
                <p>Espiritualidade: {userData.listValues[7]}</p>
                <p>Cidadania: {userData.listValues[8]}</p>
                <p>Autocuidado: {userData.listValues[9]}</p>
              </>
            )}
          </div>
          <div className={css.profile6}>
            {userData.profilePermissions &&
              userData.profilePermissions.personality && (
                <i
                  className={`material-icons ${css.cardIcon}`}
                  onClick={() => navigate("/personality")}
                >
                  edit_note
                </i>
              )}
            <h4>Personalidade</h4>
            {userData.personalityTraits &&
              userData.personalityTraits.map((p, i) => (
                <>
                  <div key={i} className={css.containerBar}>
                    <span className={css.textBar}>{traits[i]}</span>
                    <div
                      className={css.progressBar}
                      style={{
                        width:
                          Math.round(
                            (p * 100) /
                              (userData.personalityTraits[0] +
                                userData.personalityTraits[1] +
                                userData.personalityTraits[2] +
                                userData.personalityTraits[3] +
                                userData.personalityTraits[4])
                          ) + "%",
                      }}
                    >
                      <span>
                        {Math.round(
                          (p * 100) /
                            (userData.personalityTraits[0] +
                              userData.personalityTraits[1] +
                              userData.personalityTraits[2] +
                              userData.personalityTraits[3] +
                              userData.personalityTraits[4])
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      ) : (
        <div className={css.container}>
          <div className={css.title}>
            <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
              Criar um perfil !
            </button>
          </div>
        </div>
      )}
    </>
  );
};
