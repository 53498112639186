import React, { useState } from "react";

import css from "./HomePage.module.css";
import logoName from "../../assets/logoName.png";
import RochaPerfil from "../../assets/RochaPerfil.png";
import whatsappLogo from "../../assets/whatsappLogo.png";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";
import featureImage from "../../assets/feature_1.png";
import featureImage1 from "../../assets/feature_2.png";
import featureImage2 from "../../assets/feature_3.png";
import aboutImage from "../../assets/psychiatry.png";
import aboutImage1 from "../../assets/behaviour.png";
import { useNavigate } from "react-router-dom";
import ScrollToAnchor from "../../Utils/ScrollToAnchor";

export const HomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Head title="Home" description="Home - Mairini" />
      <div className={css.container}>
        <Navbar />

        <section id={css.hero}>
          <div className={css.heroContent}>
            <img src={RochaPerfil} alt="" />
            <h1>Thiago Rocha</h1>
            <p>CRM 26892 | RQE 21795</p>
            <button
              className={css.cvBtn}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=556192492842&text=Ol%C3%A1+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0",
                  "_blank"
                )
              }
            >
              AGENDAR CONSULTA COM PSIQUIATRA
            </button>
            <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
              JÁ TENHO UMA CONTA
            </button>
          </div>
        </section>

        <section className={css.features}>
          <div className={css.featuresContainer}>
            <div className={css.featureCard}>
              <i className={`material-icons`}>public</i>
              <p>Atendimento online em todo Brasil</p>
            </div>
            <div className={css.featureCard}>
              <i className={`material-icons`}>schedule</i>
              <p>Horários flexíveis</p>
            </div>
            <div className={css.featureCard}>
              <i className={`material-icons`}>person</i>
              <p>Tratamento personalizado</p>
            </div>
            <div className={css.featureCard}>
              <i className={`material-icons`}>psychology</i>
              <p>Seguimento em psiquiatria e psicoterapia</p>
            </div>
          </div>
        </section>

        <section className={css.howItWorks}>
          <h2>Como funciona?</h2>
          <div className={css.stepsContainer}>
            <div className={css.step}>
              <ion-icon
                name="create-outline"
                className="icon"
                alt="Sign Up"
              ></ion-icon>
              <h3>Contato</h3>
              <p>Agende um atendimento pelo whatsapp</p>
            </div>
            <div className={css.step}>
              <ion-icon
                name="people-outline"
                className="icon"
                alt="Connect"
              ></ion-icon>
              <h3>Atendimento</h3>
              <p>
                Na primeira consulta será elaborado um plano de tratamento
                exclusivo
              </p>
            </div>
            <div className={css.step}>
              <ion-icon
                name="school-outline"
                className="icon"
                alt="Learn"
              ></ion-icon>
              <h3>Acompanhamento</h3>
              <p>
                Durante o acompanhamento será fornecido suporte e ajustes no
                tratamento
              </p>
            </div>
          </div>
        </section>

        {/* <section className={css.resources}>
          <h2>Recursos exclusivos oferecidos</h2>
          <div className={css.resourcesContainer}>
            <div className={css.resourceCard}>
              <h3>Cartões</h3>
              <p>Cartões diários com informações sobre bem-estar.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Exercícios</h3>
              <p>Exercícios para flexibilidade cognitiva.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Agenda</h3>
              <p>Planejamento de atividades diárias.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Personalizado</h3>
              <p>Testes e questionários para desenvolvimento personalizado.</p>
            </div>
          </div>
        </section> */}

        {/* <section className={css.features}>
          <h2>Sobre a Plataforma</h2>
          <div className={css.featuresContainer}>
            <div className={css.featureCard}>
              <ion-icon
                name="bookOutline"
                className="icon"
                alt="Interactive Exercises"
              ></ion-icon>
              <h3>Interactive Exercises</h3>
              <p>Aprenda habilidades de regulação emocional.</p>
            </div>
            <div className={css.featureCard}>
              <ion-icon
                name="personCircleOutline"
                className="icon"
                alt="Expert Assistance"
              ></ion-icon>
              <h3>Expert Assistance</h3>
              <p>Treinos para melhorar a concentração.</p>
            </div>
            <div className={css.featureCard}>
              <ion-icon
                name="bar-chart-outline"
                className="icon"
                alt="Track Your Progress"
              ></ion-icon>
              <h3>Track Your Progress</h3>
              <p>Organização de uma agenda de bem-estar.</p>
            </div>
            <div className={css.featureCard}>
              <ion-icon
                name="bar-chart-outline"
                className="icon"
                alt="Track Your Progress"
              ></ion-icon>
              <h3>Track Your Progress</h3>
              <p>
                Identificação de pontos fortes e valores baseados na
                personalidade.
              </p>
            </div>
          </div>
        </section>

        <section className={css.howItWorks}>
          <h2>Como funciona</h2>
          <div className={css.stepsContainer}>
            <div className={css.step}>
              <ion-icon
                name="create-outline"
                className="icon"
                alt="Sign Up"
              ></ion-icon>
              <h3>Contato</h3>
              <p>Agende um atendimento.</p>
            </div>
            <div className={css.step}>
              <ion-icon
                name="people-outline"
                className="icon"
                alt="Connect"
              ></ion-icon>
              <h3>Atendimento</h3>
              <p>Faça uma primeira avaliação</p>
            </div>
            <div className={css.step}>
              <ion-icon
                name="school-outline"
                className="icon"
                alt="Learn"
              ></ion-icon>
              <h3>Aprender</h3>
              <p>Desenvolva suas habilidades.</p>
            </div>
          </div>
        </section>

        <section className={css.resources}>
          <h2>Recursos</h2>
          <div className={css.resourcesContainer}>
            <div className={css.resourceCard}>
              <h3>Cartões</h3>
              <p>Cartões diários com informações sobre bem-estar.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Exercícios</h3>
              <p>Exercícios para flexibilidade cognitiva.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Agenda</h3>
              <p>Planejamento de atividades diárias.</p>
            </div>
            <div className={css.resourceCard}>
              <h3>Personalizado</h3>
              <p>Testes e questionários para desenvolvimento personalizado.</p>
            </div>
          </div>
        </section> */}

        <section className={css.about}>
          <div className={css.aboutContainer}>
            <h1>Sobre</h1>
            <p>
              Thiago Rocha é mestre em ciências do comportamento pela
              Universidade de Brasília (UnB), Psiquiatra da Secretaria Estadual
              de Saúde (SES) do Distrito Federal (DF) e preceptor do programa de
              residência médica em psiquiatria do Hospital São Vicente de Paulo
              (HSVP), Professor da faculdade de medicina da UnB. Possui
              residência médica em psiquiatria realizada no Hospital de Base do
              Distrito Federal (HBDF), Graduado em medicina pela Escola Superior
              de Ciências da Saúde (ESCS).
            </p>
          </div>
          <img src={RochaPerfil} alt="" />
        </section>

        <footer className={css.footer}>
          {/* <div className={css.footerLinks}>
            <a href="#">Privacidade</a>
            <a href="#">Termos de uso</a>
          </div> */}
          {/* <div className={css.footerSocial}>
            <a href="#">
              <ion-icon
                name="logo-facebook"
                className="icon"
                alt="Facebook"
              ></ion-icon>
            </a>
            <a href="#">
              <ion-icon
                name="logo-twitter"
                className="icon"
                alt="Twitter"
              ></ion-icon>
            </a>
            <a href="#">
              <ion-icon
                name="logo-linkedin"
                className="icon"
                alt="LinkedIn"
              ></ion-icon>
            </a>
          </div> */}
          <div className={css.footerContact}>
            {/* <p>Email: info@educationalplatform.com</p> */}
            {/* <p>Phone: +55 61 92492842</p> */}
          </div>
          <div className={css.newsletterSignup}>Mairini © 2024</div>
        </footer>
      </div>
    </>
  );
};
