import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// USER

export const getUserFirebase = async (email) => {
  const docRef = collection(db, "users");
  const q = query(docRef, where("email", "==", email));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`user ${email} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getUsersFirebase = async () => {
  const docRef = collection(db, "users");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No such document");
  }
};

export const updateUserFirebase = async (user) => {
  console.log("user updated");
  await updateDoc(doc(db, "users", user.id), {
    id: user.id,
    displayName: user.displayName,
    email: user.email,
    category: user.category ? user.category : "",
    member: user.member ? user.member : false,
    staff: user.staff ? user.staff : false,
    profilePic: user.profilePic ? user.profilePic : "",
    research: user.research ? user.research : "",
    sex: user.sex ? user.sex : "",
    birthDate: user.birthDate ? user.birthDate : "",
    age: user.age ? user.age : "",
    social: user.social ? user.social : "",
    education: user.education ? user.education : "",
    listGoals: user.listGoals ? user.listGoals : [],
    listValues: user.listValues ? user.listValues : "",
    actionPlan: user.actionPlan ? user.actionPlan : "",
    personalityTraits: user.personalityTraits ? user.personalityTraits : "",
    schedule: user.schedule ? user.schedule : [],
  });
};

// APPOINTMENTS

export const getAppointmentsPatFirebase = async (userEmail) => {
  const docRef = collection(db, "appointments");
  const q = query(docRef, where("userEmail", "==", userEmail));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`appointments ${userEmail} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

// USERS

export const getUserByIdFirebase = async (id) => {
  const docRef = collection(db, "users");
  const q = query(docRef, where("id", "==", id));
  const querySnap = await getDocs(q);
  if (querySnap) {
    console.log(`user ${id} loaded`);
    return querySnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

export const getProfessionalsFirebase = async () => {
  const docRef = collection(db, "professionals");
  const docSnap = await getDocs(docRef);
  if (docSnap) {
    console.log(`mairini professionals loaded`);
    return docSnap.docs.map((doc) => ({ ...doc.data() }));
  } else {
    console.log("No appointments document");
  }
};

// EXCLUIR ?????

// export const getTagsFirebase = async () => {
//   const docRef = collection(db, "tags");
//   const docSnap = await getDocs(docRef);
//   if (docSnap) {
//     console.log("all tags loaded");
//     return docSnap.docs.map((doc) => ({ ...doc.data() }));
//   } else {
//     console.log("No such document");
//   }
// };

// export const createTagFirebase = async (tag) => {
//   await setDoc(doc(db, "tags", tag.id), {
//     id: tag.id,
//     title: tag.title,
//   });
//   console.log("tag created");
// };

// export const updateTagFirebase = async (tag) => {
//   await updateDoc(doc(db, "tags", tag.id), {
//     id: tag.id,
//     title: tag.title,
//   });
//   console.log("tag updated");
// };

// export const addTagDeckFirebase = async (tag, deckSnippet) => {
//   await setDoc(doc(db, "tagedDecks"), {
//     tagId: tag.id,
//     deckId: deckSnippet.id,
//     title: deckSnippet.title,
//     tags: deckSnippet.tags,
//     cards: deckSnippet.cards.length,
//   });
// };

// export const delTagDeckFirebase = async (tag, deck) => {
//   await deleteDoc(doc(db, "tagedDecks", tag.id), {});
// };

// // FAVORITES

// export const getFavoritesFirebase = async (user) => {
//   const docRef = doc(db, "users", user.uid, "favorites", "favorites");
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     console.log(`favorites loaded`);
//     return docSnap.data();
//   } else {
//     console.log("No such document");
//   }
// };

// export const setFavoritesFirebase = async (user, favorites) => {
//   await setDoc(doc(db, "users", user.uid, "favorites", "favorites"), {
//     favorites,
//   });
//   console.log("favorites updated");
// };
