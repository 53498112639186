import React from "react";
import { Routes, Route } from "react-router-dom";

import css from "../Home/UserPage.module.css";
import { ListeningPresent } from "./ListeningPresent";
import { NotFound } from "../NotFound";

export const TerAct = () => {
  return (
    <section className={css.login}>
      <div></div>
      <div className={css.forms}>
        <Routes>
          <Route path="/listening-present" element={<ListeningPresent />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <div></div>
    </section>
  );
};
