import React from "react";

import css from "./HomePage.module.css";
import RochaPerfil from "../../assets/RochaPerfil.png";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";

export const About = () => {
  return (
    <>
      <Head title="Home" description="Home - Mairimed" />
      <div className={css.container}>
        <Navbar />
        <div className={css.main} id="home">
          <div className={css.name}>
            <img src={RochaPerfil} alt="" />
            <h1>Thiago Rocha</h1>
            <p>
              Mestre em ciências do comportamento pela Universidade de Brasília
              (UnB), Psiquiatra da Secretaria Estadual de Saúde (SES) do
              Distrito Federal (DF) e preceptor do programa de residência médica
              em psiquiatria do Hospital São Vicente de Paulo (HSVP), Professor
              da faculdade de medicina da UnB. Possui residência médica em
              psiquiatria realizada no Hospital de Base do Distrito Federal
              (HBDF), Graduado em medicina pela Escola Superior de Ciências da
              Saúde (ESCS).
            </p>
          </div>
        </div>
        <div id={css.footer}>
          <ul>
            <li>Mairini © 2024</li>
          </ul>
        </div>
      </div>
    </>
  );
};
