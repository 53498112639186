import React, { useContext } from "react";

import css from "./Today.module.css";
import { Head } from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";

export const Explore = () => {
  const { mindList, moveList, nutriList } = useContext(ClinicContext);

  return (
    <div className={css.container}>
      <Head title="User" description={"User - Mairini"} />
      <section>
        {/* <div className={css.myActivities}>
          <div className={`${css.feature} ${css.featureMind}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              radio_button_unchecked
            </i>
            <p>MIND</p>
          </div>
          <div className={`${css.feature} ${css.featureMove}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              change_history
            </i>
            <p>MOVE</p>
          </div>
          <div className={`${css.feature} ${css.featureNutri}`}>
            <i className={`material-icons ${css.sidebarIcon}`}>
              check_box_outline_blank
            </i>
            <p>NUTRI</p>
          </div>
        </div> */}
        {mindList.map((m) => (
          <div className={css.card}>
            <h4>{m.title}</h4>
          </div>
        ))}
      </section>
    </div>
  );
};
