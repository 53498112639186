import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import css from "./Today.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";

export const Today = () => {
  const { userData } = useContext(UserContext);
  const [taskModal, setTaskModal] = useState(false);
  const [pillar, setPillar] = useState("mind");
  const [tasks, setTasks] = useState("");
  const [h05h07, seth05h07] = useState(new Array(7).fill(""));
  const [h07h09, seth07h09] = useState(new Array(7).fill(""));
  const [h09h11, seth09h11] = useState(new Array(7).fill(""));
  const [h11h13, seth11h13] = useState(new Array(7).fill(""));
  const [h13h15, seth13h15] = useState(new Array(7).fill(""));
  const [h15h17, seth15h17] = useState(new Array(7).fill(""));
  const [h17h19, seth17h19] = useState(new Array(7).fill(""));
  const [h19h21, seth19h21] = useState(new Array(7).fill(""));
  const [h21h23, seth21h23] = useState(new Array(7).fill(""));
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.schedule) {
      seth05h07(userData.schedule.h05h07);
      seth07h09(userData.schedule.h07h09);
      seth09h11(userData.schedule.h09h11);
      seth11h13(userData.schedule.h11h13);
      seth13h15(userData.schedule.h13h15);
      seth15h17(userData.schedule.h15h17);
      seth17h19(userData.schedule.h17h19);
      seth19h21(userData.schedule.h19h21);
      seth21h23(userData.schedule.h21h23);
    }
  }, [userData]);

  if (userData) {
    return (
      <div className={css.container}>
        <Head title="Today" description={"User - Mairini"} />
        <section>
          <div className={css.card}>
            <div className={css.cardHead}>
              <div>
                <i className={`material-icons ${css.cardUserIcon}`}>
                  account_circle
                </i>
                <h4>{userData.displayName}</h4>
              </div>
              <div>
                <h4>{dayjs().format("DD.MM.YY")}</h4>
              </div>
            </div>
            <p>Personalidade</p>
            <h4>Introvertido</h4>
            <h4>Criativo</h4>
            <p>Direções da vida</p>
            <h4>Família</h4>
            <h4>Tabalho</h4>
            <h4>Lazer</h4>
          </div>
          <div className={`${css.card}`}>
            <div className={`${css.cardHead} ${css.cardMind}`}>
              <div>
                <i className={`material-icons ${css.sidebarIcon}`}>
                  radio_button_unchecked
                </i>
                <h4>Mind</h4>
              </div>
            </div>
            <div className={css.cardBody}>
              <span>
                <i className={`material-icons ${css.sidebarIcon}`}>schedule</i>
                <p>Manhã</p>
              </span>
              <h4>Ouvindo o presente</h4>
              <p>
                Treino de concentração ouvindo o ambiente enquanto observa os
                pensamentos.
              </p>
            </div>
            <button
              className="defaultButton"
              onClick={() => navigate("/listening-present")}
            >
              Iniciar
            </button>
          </div>

          {/* <div className={`${css.card} ${css.cardMove}`}>
            <span>
              <i className={`material-icons ${css.sidebarIcon}`}>
                change_history
              </i>
              <h4>Move</h4>
            </span>
          </div>
          <div className={`${css.card} ${css.cardNutri}`}>
            <span>
              <i className={`material-icons ${css.sidebarIcon}`}>
                check_box_outline_blank
              </i>
              <h4>Nutri</h4>
            </span>
          </div> */}

          {/* <div className={css.card}>
            <h4>Agenda {dayjs().format("DD.MM.YY")}</h4>
            <div className={css.scheduleGrid}>
              <div>
                <h5>MANHÃ</h5>
                <p
                  onClick={() => {
                    h05h07[dayjs().day()].title
                      ? setTaskModal(h05h07[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h05h07[dayjs().day()].pillar
                      ? setPillar(h05h07[dayjs().day()].pillar)
                      : setPillar("");
                    h05h07[dayjs().day()].tasks
                      ? setTasks(h05h07[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h05h07[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h07h09[dayjs().day()].title
                      ? setTaskModal(h07h09[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h07h09[dayjs().day()].pillar
                      ? setPillar(h07h09[dayjs().day()].pillar)
                      : setPillar("");
                    h07h09[dayjs().day()].tasks
                      ? setTasks(h07h09[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h07h09[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h09h11[dayjs().day()].title
                      ? setTaskModal(h09h11[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h09h11[dayjs().day()].pillar
                      ? setPillar(h09h11[dayjs().day()].pillar)
                      : setPillar("");
                    h09h11[dayjs().day()].tasks
                      ? setTasks(h09h11[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h09h11[dayjs().day()].title}
                </p>
              </div>
              <div>
                <h5>TARDE</h5>
                <p
                  onClick={() => {
                    h11h13[dayjs().day()].title
                      ? setTaskModal(h11h13[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h11h13[dayjs().day()].pillar
                      ? setPillar(h11h13[dayjs().day()].pillar)
                      : setPillar("");
                    h11h13[dayjs().day()].tasks
                      ? setTasks(h11h13[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h11h13[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h13h15[dayjs().day()].title
                      ? setTaskModal(h13h15[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h13h15[dayjs().day()].pillar
                      ? setPillar(h13h15[dayjs().day()].pillar)
                      : setPillar("");
                    h13h15[dayjs().day()].tasks
                      ? setTasks(h13h15[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h13h15[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h15h17[dayjs().day()].title
                      ? setTaskModal(h15h17[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h15h17[dayjs().day()].pillar
                      ? setPillar(h15h17[dayjs().day()].pillar)
                      : setPillar("");
                    h15h17[dayjs().day()].tasks
                      ? setTasks(h15h17[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h15h17[dayjs().day()].title}
                </p>
              </div>
              <div>
                <h5>NOITE</h5>
                <p
                  onClick={() => {
                    h17h19[dayjs().day()].title
                      ? setTaskModal(h17h19[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h17h19[dayjs().day()].pillar
                      ? setPillar(h17h19[dayjs().day()].pillar)
                      : setPillar("");
                    h17h19[dayjs().day()].tasks
                      ? setTasks(h17h19[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h17h19[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h19h21[dayjs().day()].title
                      ? setTaskModal(h19h21[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h19h21[dayjs().day()].pillar
                      ? setPillar(h19h21[dayjs().day()].pillar)
                      : setPillar("");
                    h19h21[dayjs().day()].tasks
                      ? setTasks(h19h21[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h19h21[dayjs().day()].title}
                </p>
                <p
                  onClick={() => {
                    h21h23[dayjs().day()].title
                      ? setTaskModal(h21h23[dayjs().day()].title)
                      : setTaskModal("Início da manhã");
                    h21h23[dayjs().day()].pillar
                      ? setPillar(h21h23[dayjs().day()].pillar)
                      : setPillar("");
                    h21h23[dayjs().day()].tasks
                      ? setTasks(h21h23[dayjs().day()].tasks)
                      : setTasks([]);
                  }}
                >
                  {h21h23[dayjs().day()].title}
                </p>
              </div>
            </div>
          </div> */}

          {/* <div className={css.card}>
            <h4>Atividade Terapêutica</h4>
            <h5>{userData.terAct.title}</h5>
            <p>{userData.terAct.content}</p>
            <button
              className="greenButton"
              // onClick={() => navigate(`/teract/${userData.terAct.slug}`)}
              onClick={() => navigate(`/teract/listening-present`)}
            >
              Iniciar
            </button>
          </div> */}
        </section>
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <section>
          <div className={css.schedule}>
            <h2>Agenda {dayjs().format("DD.MM.YY")}</h2>
            <h5>Aqui você visualiza a agenda do seu dia.</h5>
          </div>
        </section>
      </div>
    );
  }
};
