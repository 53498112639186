import React, { useContext, useState, useEffect } from "react";

import css from "./Navbar.module.css";
import logoName from "../../assets/logoName.png";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import HeaderScrolled from "../../Utils/HeaderScrolled";

export const Navbar = () => {
  const { user, userLogout } = useContext(UserContext);
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY;
        setPos(window.scrollY);
        if (position > 0) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div>
      <nav className={nav ? `${css.nav} ${css.active}` : css.nav}>
        <a href="/" className={css.logo}>
          <img src={logoName} alt="" />
        </a>
        <input type="checkbox" id="menuBtn" className={css.menuBtn} />
        <label className={css.menuIcon} htmlFor="menuBtn">
          <span className={css.navIcon}></span>
        </label>
        <ul className={css.menu}>
          {/* <li>
            <Link
              to="/home"
              className={pathname === "/home" ? css.btnStart : css.btnIn}
            >
              Início
            </Link>
          </li>
          <li>
            <Link
              to="/login"
              className={pathname === "/login" ? css.btnStart : css.btnIn}
            >
              Entrar
            </Link>
          </li> */}

          {/* <li>
            <Link
              to="/audios"
              className={pathname === "/audios" ? css.btnStart : css.btnIn}
            >
              Áudios
            </Link>
          </li>
          <li>
            <Link
              to="/articles"
              className={pathname === "/articles" ? css.btnStart : css.btnIn}
            >
              Artigos
            </Link>
          </li> */}

          {/* <li>
            {!user ? (
              <Link to="/login" className={css.btnIn}>
                Entrar
              </Link>
            ) : (
              <Link onClick={userLogout}>Sair</Link>
            )}
          </li> */}
        </ul>
      </nav>
    </div>
  );
};
