import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import css from "./content.module.css";

export const Content = (props) => {
  // const location = useLocation();
  let className = props.isMenuVisible ? css.container : css.noSidebar;

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      props.setIsMenuVisible(false);
    } else {
      props.setIsMenuVisible(true);
    }
  }, []);

  const showMenu = () => {
    if (window.innerWidth <= 1200) {
      props.setIsMenuVisible(false);
    } else {
      props.setIsMenuVisible(true);
    }
  };

  window.addEventListener("resize", showMenu);

  return (
    <>
      {/* <Breadcrumbs list={location.pathname} /> */}
      <div className={css.container}>
        <div></div>
        {props.component}
        <Outlet />
        <div></div>
      </div>
    </>
  );
};
