import React from "react";

import css from "./HomePage.module.css";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";

export const Audios = () => {
  return (
    <>
      <Head title="Audios" description="Audios - Mairini" />
      <Navbar />
      <div className={css.container}>
        <div className={css.cardContainer}>
          <div className={css.card}></div>
          <div className={css.card}></div>
          <div className={css.card}></div>
          <button
            className={css.cvBtn}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=556192492842&text=Ol%C3%A1+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0",
                "_blank"
              )
            }
          >
            AGENDAR ATENDIMENTO
          </button>
        </div>
      </div>
    </>
  );
};
