import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import css from "./Posts.module.css";
import { Head } from "../../Utils/Head";
import { ClinicContext } from "../../Context/ClinicContext";

export const Post = () => {
  const params = useParams();
  const { mindList, moveList, nutriList } = useContext(ClinicContext);
  const [post, setPost] = useState([]);

  useEffect(() => {
    if (mindList.map((m) => m.slug).indexOf(params.slug) === 0) {
      setPost(mindList[mindList.map((m) => m.slug).indexOf(params.slug)]);
    } else if (moveList.map((m) => m.slug).indexOf(params.slug) === 0) {
      setPost(moveList[moveList.map((m) => m.slug).indexOf(params.slug)]);
    } else if (nutriList.map((m) => m.slug).indexOf(params.slug) === 0) {
      setPost(nutriList[nutriList.map((m) => m.slug).indexOf(params.slug)]);
    }
  }, []);

  return (
    <div className={css.container}>
      <Head
        title="ListeningPresent"
        description={"ListeningPresent - Mairini"}
      />
      <h4>{post.title}</h4>
      <p>{post.content}</p>
      <img src={post.image} alt="" />
    </div>
  );
};
