import React from "react";

import css from "./Today.module.css";
import RochaPerfil from "../../assets/RochaPerfil.png";
import { Button } from "../Forms/Button";
import { Input } from "../Forms/Input";

export const Contact = () => {
  return (
    <div className="containerContent">
      <div className={css.main} id="home">
        <div className={css.name}>
          <img src={RochaPerfil} alt="" />
          <h1>Thiago Rocha</h1>
          <button
            className={css.cvBtn}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=556192492842&text=Ol%C3%A1+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0",
                "_blank"
              )
            }
          >
            Agendar atendimento
          </button>
        </div>
      </div>
    </div>
  );
};
